<template>
    <div class="max">
        <div v-if="!formProgress">
            <b-row>
                <b-col>
                    <h5 class="text-center">
                        {{ $t('speaking_exam').toUpper() }}<br>
                    </h5>
                    <div class="spinner over height-200" v-if="pageLoading">
                        <b-spinner
                            label="Spinning"
                            class="text-primary">
                        </b-spinner>
                    </div>
                    <div v-else>
                        <table class="table table-bordered mb-2">
                            <tbody>
                                <tr>
                                    <td class="bg-light width-150">{{ $t('date') }}</td>
                                    <td>
                                        <span v-if="partOfSpeaking && partOfSpeaking.date">
                                            {{ partOfSpeaking.date | dateFormat($i18n.locale) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-light">{{ $t('start_time') }}</td>
                                    <td>
                                        <span v-if="partOfSpeaking && partOfSpeaking.start_time && partOfSpeaking.end_time">
                                            {{ partOfSpeaking.start_time }} - {{ partOfSpeaking.end_time }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-light">{{ $t('meeting_duration') }}</td>
                                    <td>{{ partOfSpeaking && partOfSpeaking.meeting_duration ? partOfSpeaking.meeting_duration + ' ' + $t('minute') : '-' }}</td>
                                </tr>
                                <tr>
                                    <td class="bg-light">{{ $t('instructors') }}</td>
                                    <td>
                                        <ul v-if="partOfSpeaking && partOfSpeaking.instructors_details" class="pl-4 m-0">
                                            <li v-for="instructor in partOfSpeaking.instructors_details">
                                                {{ instructor.name }} {{ instructor.surname }}
                                            </li>
                                        </ul>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-light">{{ $t('available_hours') }}</td>
                                    <td>
                                        <ul v-if="partOfSpeaking && partOfSpeaking.available_hours" class="pl-4 m-0">
                                            <li v-for="hour in partOfSpeaking.available_hours">
                                                {{ hour.from }} - {{ hour.to }}
                                            </li>
                                        </ul>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-center" v-if="partOfSpeaking && partOfSpeaking.id">
                            <b-button variant="primary"
                                      @click="showForm('update',partOfSpeaking.id, 'Speaking')"
                                      class="mr-1"
                                      size="sm"
                                      v-if="checkPermission('placementexampart_update')"
                            >
                                {{ $t('edit').toUpper() }}
                            </b-button>
                            <b-button variant="danger"
                                      @click="deleteForm(partOfSpeaking.id)"
                                      size="sm"
                            >
                                {{ $t('delete').toUpper() }}
                            </b-button>
                        </div>
                        <div class="d-flex justify-content-center" v-else>
                            <b-button variant="primary"
                                      @click="showForm('create', null, 'Speaking')"
                                      size="sm"
                                      v-if="checkPermission('placementexampart_store')"
                            >
                                {{ $t('add') }}
                            </b-button>
                        </div>
                    </div>
                </b-col>
                <b-col>
                    <h5 class="text-center">
                        {{ $t('written_exam').toUpper() }}<br>
                    </h5>
                    <div class="spinner over height-200" v-if="pageLoading">
                        <b-spinner
                            label="Spinning"
                            class="text-primary">
                        </b-spinner>
                    </div>
                    <div v-else>
                        <table class="table table-bordered mb-2">
                            <tbody>
                                <tr>
                                    <td class="bg-light width-150">{{ $t('date') }}</td>
                                    <td>
                                        <span v-if="partOfWritten && partOfWritten.date">
                                            {{ partOfWritten.date | dateFormat($i18n.locale) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-light">{{ $t('start_time') }}</td>
                                    <td>
                                        <span v-if="partOfWritten && partOfWritten.start_time && partOfWritten.end_time">
                                            {{ partOfWritten.start_time }} - {{ partOfWritten.end_time }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-light">{{ $t('meeting_duration') }}</td>
                                    <td>{{ partOfWritten && partOfWritten.meeting_duration ? partOfWritten.meeting_duration + ' ' + $t('minute') : '-' }}</td>
                                </tr>
                                <tr>
                                    <td class="bg-light">{{ $t('instructors') }}</td>
                                    <td>
                                        <ul v-if="partOfWritten && partOfWritten.instructors_details" class="pl-4 m-0">
                                            <li v-for="instructor in partOfWritten.instructors_details">
                                                {{ instructor.name }} {{ instructor.surname }}
                                            </li>
                                        </ul>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-light">{{ $t('available_hours') }}</td>
                                    <td>
                                        <ul v-if="partOfWritten && partOfWritten.available_hours" class="pl-4 m-0">
                                            <li v-for="hour in partOfWritten.available_hours">
                                                {{ hour.from }} - {{ hour.to }}
                                            </li>
                                        </ul>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-center" v-if="partOfWritten && partOfWritten.id">
                            <b-button variant="primary"
                                      @click="showForm('update',partOfWritten.id, 'Written')"
                                      class="mr-1"
                                      size="sm"
                                      v-if="checkPermission('placementexampart_update')"
                            >
                                {{ $t('edit').toUpper() }}
                            </b-button>
                            <b-button variant="danger"
                                      @click="deleteForm(partOfWritten.id)"
                                      size="sm"
                            >
                                {{ $t('delete').toUpper() }}
                            </b-button>
                        </div>
                        <div class="d-flex justify-content-center" v-else>
                            <b-button variant="primary"
                                      @click="showForm('create', null, 'Written')"
                                      size="sm"
                                      v-if="checkPermission('placementexampart_store')"
                            >
                                {{ $t('add') }}
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div v-if="formProgress">
            <create-update-form :placementExamId="placementExamId"
                                :formProgress.sync="formProgress"
                                :formId.sync="formId"
                                :formType.sync="formType"
                                v-if="formProgress"
                                :key="'create-update-form-'+key"
                                @createFormSuccess="createFormSuccess($emit)"
                                @updateFormSuccess="updateFormSuccess($emit)"
                                @backForm="backForm($emit)"
            >
            </create-update-form>
        </div>
    </div>
</template>

<script>
    // Components
    import CommonModal from "@/components/elements/CommonModal";
    import CreateUpdateForm from "./CreateUpdateForm";

    // Services
    import PlacementExamPartService from "@/services/PlacementExamPartService"
    import PlacementExamService from "@/services/PlacementExamService"

    // Other
    import qs from "qs";

    export default {
        components: {
            CommonModal,
            CreateUpdateForm,
        },
        props: {
            placementExamId: {
                type: Number,
            }
        },
        data() {
            return {
                pageLoading: false,

                placementExam: [],
                placementExamParts: [],
                placementExamPartTypes: ['Written', 'Speaking'],

                partOfSpeaking: null,
                partOfWritten: null,

                formId: null,
                formProgress: null,
                formType: null,
                key: 0
            }
        },
        created() {
            this.getPlacementExam(this.placementExamId);
        },
        methods: {
            clearForm() {
                this.formId = null
                this.formProgress = null
                this.formType = null
                this.key++
            },
            getPlacementExam(id) {
                this.pageLoading = true
                PlacementExamService.get(id)
                                    .then(response => {
                                        this.placementExam = response.data.data;
                                    })
                                    .then(() => {
                                        this.getPlacementExamParts(id);
                                    })
                                    .catch(e => {
                                        this.placementExam = [];
                                        this.placementExamParts = [];

                                        this.$router.push('/404');
                                    })
                                    .finally(() => {
                                        this.pageLoading = false;
                                    });

            },
            getPlacementExamParts(id) {
                const config = {
                    params: {
                        filter: {
                            placement_exam_id: id,
                        },
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                PlacementExamPartService.getAll(config)
                                        .then(response => {
                                            this.placementExamParts = response.data.data

                                            this.partOfSpeaking = null;
                                            this.partOfWritten = null;
                                            this.placementExamParts.forEach(item => {
                                                if (item?.type == 'Speaking') {
                                                    this.partOfSpeaking = item
                                                }
                                                if (item?.type == 'Written') {
                                                    this.partOfWritten = item
                                                }
                                            })
                                        })
                                        .finally(() => {
                                            this.pageLoading = false;
                                        });
            },

            // Delete
            deleteForm(id) {
                if(this.checkPermission('placementexampart_delete')){
                    this.$swal.fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no'),
                    })
                        .then((result) => {
                            if (result.isConfirmed) {
                                PlacementExamPartService.del(id)
                                                        .then(response => {
                                                            this.$toast.success(this.$t('api.' + response.data.message));
                                                            this.clearForm();
                                                            this.getPlacementExamParts(this.placementExamId)
                                                        })
                                                        .catch(error => {
                                                            this.$toast.error(this.$t('api.' + error.data.message));
                                                        });
                            }
                        })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            // Show Form
            showForm(process, id = null, type) {
                this.clearForm();
                this.formProgress = process
                this.formId = id
                this.formType = type
            },
            createFormSuccess() {
                this.clearForm();
                this.getPlacementExamParts(this.placementExamId)
            },
            updateFormSuccess() {
                this.clearForm();
                this.getPlacementExamParts(this.placementExamId)
            },
            backForm() {
                this.clearForm();
            }
        }
    }
</script>

