<template>
    <div>
        <label v-if="name">{{ name }}</label>
        <div class="hours-area" :class="validate != '' ? 'error':''">
            <div class="d-flex">
                <div class="mr-1 mb-1 flex-grow-1">
                    <label>{{ $t('start_time') }}</label>
                    <b-form-input type="time" v-model="start_time"></b-form-input>
                </div>
                <div class="mr-1 mb-1 flex-grow-1">
                    <label>{{ $t('end_time') }}</label>
                    <b-form-input type="time" v-model="end_time"></b-form-input>
                </div>
                <div class="mr-1 mb-1">
                    <label>&nbsp;</label>
                    <b-button variant="primary" @click="addHours"><i class="ri-add-line"></i></b-button>
                </div>
            </div>
            <div class="mt-3" v-if="hours">
                <div v-for="(hour,index) in hours"
                     :key="'hour'+index"
                     class="mb-2"
                >
                    <div class="d-flex align-items-center">
                        <b-badge class="mr-2" @click="removeHour(index)">
                            <i class="ri-close-line cursor-pointer"></i>
                        </b-badge>
                        {{ hour.from }} - {{ hour.to }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style type="scss">
    .hours-area {
        border: 1px solid #e9ecef;
        border-radius: .375rem;
        height: 310px;
        padding: 15px;
        overflow-y: auto;
    }

    .hours-area.error {
        border-color: #f696a8;
    }
</style>

<script>
    export default {
        props: {
            value: {
                type: Array,
                default: []
            },
            name: {
                type: String,
                default: null
            },
            validate: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                hours: [],
                start_time: null,
                end_time: null,
            }
        },
        watch: {
            value: function (newValue) {
                this.hours = newValue
            }
        },
        methods: {
            addHours() {
                if (this.start_time && this.end_time) {
                    if (this.hours.some((hour) => hour.from === this.start_time && hour.to === this.end_time)) {
                        this.$toast.error(this.$t('the_values_you_entered_have_already_been_added'));
                    }
                    else {
                        this.hours.push({'from': this.start_time, 'to': this.end_time});
                    }
                    this.start_time = null
                    this.end_time = null
                    this.$emit('input', this.hours)
                }
            },
            removeHour(index) {
                if (index > -1 && this.hours[index]) {
                    this.$swal.fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no'),
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.hours.splice(index, 1);
                            this.$emit('input', this.hours)
                        }
                    });
                }
            },
            handleInput(event) {
                this.$emit('input', event)
            }
        },
    };
</script>
